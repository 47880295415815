import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const msg = require('../../message/message_ja.js')

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  }
})

class RoomJoinForm extends React.PureComponent {
  state = {
    secret: "",
    secretError: "",
    joinAsObserver: false,
  }

  validateSecret() {
    const { secret } = this.state
    if (secret.length === 0) {
      return [ false, '合言葉を入力してください']
    }
    return [ true, secret ]
  }

  joinRoom() {
    const secretValRes = this.validateSecret()
    if (!secretValRes[0]) {
      this.setState({secretError: secretValRes[1]})
      return;
    }
    const isPlayer = !this.state.joinAsObserver
    this.props.client.room.join(this.state.secret, isPlayer)
      .then((room) => { this.props.onRoomJoin(room) })
      .catch((arg) => {
        const [, errorId] = arg
        this.setState({secretError: msg.getMessage(errorId)})
      })
  }

  render() {
    const {classes} = this.props
    return (
      <Paper className={classes.paper}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography color="textSecondary">合言葉で入室</Typography>
            <FormControl className={classes.formControl}>
              <TextField
                required
                error={this.state.secretError.length !== 0}
                label="合言葉"
                value={this.state.secret}
                margin="normal"
                onChange={it => this.setState({secret: it.target.value})} />
              { this.state.secretError.length !== 0 &&
                 <FormHelperText error>{this.state.secretError}</FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.joinAsObserver}
                    onChange={(x) => {
                      this.setState({joinAsObserver: x.target.checked})}}
                    value="joinAsObserver"
                  />
                }
                label="観戦者として参加する。" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button size="small" color="primary"
              onClick={it => this.joinRoom() }>入室する</Button>
            <Button size="small" color="primary"
              onClick={it => this.props.onCancel() }>キャンセル</Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

RoomJoinForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoomJoinForm)
