import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class GamePlaying extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      keywordVisible: false,
    }
  }

  handleQuitTalk() {
    this.client.game.quit_talk(this.game.id).then(
      (newGame) => { this.onChange(newGame) });
  }

  render() {
    const { game, classes } = this.props
    return (
      <Paper className={classes.paper}>
        <Typography variant="h5"
          onClick={(x) => this.setState({keywordVisible: true})}
          style={{display: this.state.keywordVisible ? 'none' : 'block'}}>
              キーワードを確認するには、ここをタップ。
        </Typography>
        <Typography variant="h5"
          onClick={(x) => this.setState({keywordVisible: false})}
          style={{display: this.state.keywordVisible ? 'block' : 'none'}}>
              キーワードは「{game.word}」です。
              タップで戻ります。
        </Typography>
      </Paper>
    )
  }
}

export default withStyles(styles)(GamePlaying)
