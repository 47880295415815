import PlayerClient from './player.js'
import RoomClient from './room.js'
import PresetClient from './preset.js'
import GameClient from './game.js'

export default class Client {
  constructor(channel) {
    this.player = new PlayerClient(channel)
    this.room = new RoomClient(channel)
    this.game = new GameClient(channel)
    this.preset = new PresetClient(channel)
  }
}
