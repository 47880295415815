import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const msg = require('../../message/message_ja.js')

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit * 2,
  }
});

class RoomCreateForm extends React.PureComponent {
  state = {
    title: "",
    titleError: "",
    secret: "",
    secretError: "",
  }

  validateTitle() {
    const { title } = this.state
    if (title.length === 0) {
      return [ false, 'タイトルを入力してください']
    }
    if (title.length >= 32) {
      return [ false, 'タイトルは32文字以内でお願いします']
    }
    return [ true, '']
  }

  validateSecret() {
    const { secret } = this.state
    if (secret.length === 0) {
      return [ false, '合言葉を入力してください']
    }
    if (secret.length >= 32) {
      return [ false, '合言葉は32文字以内でお願いします']
    }
    return [ true, '' ]
  }

  handleCreate() {
    const titleValRes = this.validateTitle()
    const secretValRes = this.validateSecret()

    if (!titleValRes[0] || !secretValRes[0]) {
      this.setState({titleError: titleValRes[1], secretError: secretValRes[1]})
      return;
    }
    this.props.client.room.create(this.state.title, this.state.secret)
      .then(this.props.onRoomJoin)
      .catch((arg) => {
        const [ , errorId] = arg
        this.setState({secretError: msg.getMessage(errorId)})
      })
  }

  render() {
    const { classes } = this.props

    return (
    <Paper className={classes.paper}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography color="textSecondary">部屋を作る</Typography>
          <FormControl className={classes.formControl}>
            <TextField
              required
              error={this.state.titleError.length !== 0}
              label="部屋名"
              value={this.state.title}
              margin="normal"
              onChange={it => this.setState({title: it.target.value})} />
            { this.state.titleError.length !== 0 &&
              <FormHelperText error>{this.state.titleError}</FormHelperText>
            }
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              required
              error={this.state.secretError.length !== 0}
              label="合言葉"
              value={this.state.secret}
              margin="normal"
              onChange={it => this.setState({secret: it.target.value})} />
            { this.state.secretError.length !== 0 &&
              <FormHelperText error>{this.state.secretError}</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}>
        <Button size="small" color="primary"
          onClick={it => this.handleCreate() }>作成する</Button>
        <Button size="small" color="primary"
          onClick={it => this.props.onCancel() }>キャンセル</Button>
        </Grid>
      </Grid>
    </Paper>
    )
  }
}

RoomCreateForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoomCreateForm)
