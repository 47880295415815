import React from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  }
});

function GameWordGuess(props) {
  const { classes, game } = props
  const selectedPlayerId = game.minorityCandidates[0]
  const selectedPerson = game.players.find((ele) => ele.id === selectedPlayerId)
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">「{selectedPerson.name}」が選択されました。</Typography>
      <Typography variant="h5">選ばれたのは少数派でした。</Typography>
      <Typography variant="h5">現在は少数派がお題を当てるターンです。 </Typography>
    </Paper>
  )
}

GameWordGuess.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameWordGuess)
