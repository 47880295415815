const err = require('../errors/error_ids.js')

const MESSAGE = {}
MESSAGE[err.ERR_SECRET_ALREADY_IN_USE] = 'この合言葉はすでに使われているようです'
MESSAGE[err.ERR_NO_ROOM_FOUND] = '部屋が見つかりませんでした'

exports.getMessage = (key) => {
  const r = MESSAGE[key]
  if (!r) {
    return 'Unknown Error: ' + key
  } else {
    return r
  }
}
