import React from 'react'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Room from './room/room.js'
import Game from './game/game.js'
import Welcome from './welcome.js'
import OKCancelDialog from './common/ok_cancel_dialog.js'
import qrcode from '../images/qrcode.png'

const styles = (theme) => ({
  root: { flexGrow: 1, },
  grow: { flexGrow: 1, },
  toolbar: theme.mixins.toolbar,
});


function MainContent(props) {
  const { client, player, room, game,
    onRoomChange, onGameChange, onNameChange } = props

  if (!player.name) {
    return <Welcome client={client} onNameChange={onNameChange} />
  } else if (!game) {
    return <Room client={client} player={player} room={room} game={game}
      onRoomChange={onRoomChange} onGameChange={onGameChange}
      onNameChange={onNameChange} />
  } else {
    return <Game client={client} onChange={onGameChange} player={player}
      game={game} />
  }
}

class Main extends React.PureComponent {
  state = {
    anchorEl: null,
    showResetConfirm: false,
    showQRCode: false
  };

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(target) {

    if (target === 'reset') {
      this.setState({ showResetConfirm: true, anchorEl: null });
    } else if (target === 'qrcode') {
      this.setState({ showQRCode: true, anchorEl: null });
    } else {
      this.setState({ anchorEl: null });
    }
  }

  allReset(yesNo) {
    this.setState({ showResetConfirm: false })
    if (yesNo) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      window.location.reload();
    }
  }

  render() {
    const { classes, client, player, room, game,
      onRoomChange, onGameChange, onNameChange } = this.props

    const { anchorEl } = this.state;
    return (
      <div className={classes.root}>
        { this.state.showResetConfirm &&
          <OKCancelDialog message={"すべてなかったことにしますか？"}
            onClose={this.allReset.bind(this)} />
        }
        { this.state.showQRCode &&
          <OKCancelDialog message={
            <img src={qrcode} alt="QR Code" />
          } onClose={(x) => {this.setState({ showQRCode: false }) }} />
        }
        <AppBar>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Word Wolf (beta)
            </Typography>
            <IconButton color="inherit"
              onClick={(x) => {this.setState({anchorEl: x.currentTarget})}} >
              <MenuIcon /></IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={(x) => {this.handleClose(null)}} >
              <MenuItem onClick={(x) => {this.handleClose('reset')}}>
                設定をリセット
              </MenuItem>
              <MenuItem onClick={(x) => {this.handleClose('qrcode')}}>
                QRコード
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <MainContent
          client={client}
          player={player}
          room={room}
          game={game}
          onNameChange={ onNameChange }
          onRoomChange={ onRoomChange }
          onGameChange={ onGameChange }
        />
      </div>
    )
  }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Main);
