import React from 'react'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ObserverController from '../common/game_observer_controller.js'
import GameCreated from './created.js'
import GamePlaying from './playing.js'
import GameVoting from './voting.js'
import GameWordGuess from './word_guess.js'
import GameObserverView from '../common/game_detail_view.js';
import AlarmController from '../common/alarm_controller.js';
import OKDialog from '../common/ok_dialog.js'
import CountdownTimer from '../common/countdown_timer.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  }
});

function GameDetail(props) {
  const { game, player, client, onChange } = props
  switch (game.state) {
    case 'CREATED':
      return <GameCreated game={game} />
    case 'PLAYING':
      return <GamePlaying game={game} />
    case 'VOTING':
      return <GameVoting game={game} player={player} client={client}
        onChange={onChange} />
    case 'WORD_GUESS':
      return <GameWordGuess game={game} />
    case 'MINORITY_WIN':
    case 'MAJORITY_WIN':
    case 'TERMINATED':
      throw Error('Result page is not handled by GameDetail')
    default:
      return <div>Unknown State {game.state}</div>
  }
}

function Game(props) {
  const { classes, game, player, onChange, client } = props


  if (game.mode === 'observer') {
    return <GameObserverView client={client} game={game} player={player}
      onChange={onChange} title="ゲームの状況"/>
  } else if (game.state === 'MINORITY_WIN' || game.state === 'MAJORITY_WIN' ||
      game.state === 'TERMINATED') {
    const winner = game.state === 'MINORITY_WIN' ? '少数派' : '多数派'
    return (
      <>
        { game.state !== 'TERMINATED' &&
          <OKDialog message={
            <>
            <Typography variant="h5" component="h2">ゲームが終了しました</Typography>
            <Typography variant="h4" component="h2">{winner}の勝利</Typography>
            </>
          }/>
        }
        <GameObserverView client={client} game={game} player={player}
          onChange={onChange} title="ゲームの結果"/>
      </>
    )
  } else {
    return (
      <>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography color="textSecondary">テーマ</Typography>
            <Typography variant="h3">{game.theme}</Typography>
          </Paper>
        </Grid>
      { game.state === 'PLAYING' &&
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5">残り時間</Typography>
            <Typography variant="h2"><CountdownTimer game={game} /></Typography>
          </Paper>
        </Grid>
      }
        <Grid item xs={12}>
          <GameDetail game={game} player={player} client={client} onChange={onChange}/>
        </Grid>
        <Grid item xs={12}>
          { game.state === 'PLAYING' &&
            <AlarmController game={game} player={player}/>
          }
        </Grid>
        <Grid item xs={12}>
          <ObserverController game={game} player={player}
            onChange={onChange} client={client} />
        </Grid>
      </Grid>
      </>
    )
  }
}

Game.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Game)
