import React from 'react';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

function VotingView(props) {
  const { classes, game } = props;
  const allPlayers = game.minPlayers.concat(game.majPlayers)
  const idName = allPlayers.reduce((m, o) => { m[o.id] = o.name; return m}, {})

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">投票状況</Typography>
        </Grid>
      </Grid>
      {Object.entries(game.vote).map(x =>
        <Grid container spacing={24} key={x[0]}>
          <Grid item xs={5} align="center">
            <Chip key={"from" + x[0]} label={idName[x[0]]} />
          </Grid>
          <Grid item xs={2} align="center">&#9655;</Grid>
          <Grid item xs={5} align="center">
            <Chip key={"to" + x[1]} label={idName[x[1]]} />
          </Grid>
        </Grid>
      )
      }
    </Paper>
  )
}

VotingView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VotingView);
