import Storage from '../storage/storage.js'

const SOCKET_URL_DEV = 'wss://0642ygqbjl.execute-api.us-east-1.amazonaws.com/dev'
const SOCKET_URL_PROD = 'wss://i8qpo89jvh.execute-api.us-west-1.amazonaws.com/prod'

class JSONChannel {
  constructor(messageHandler, onConnectHandler) {
    this.__queue = []
    this.__messageHandler = messageHandler
    this.__onConnectHandler = onConnectHandler
    this.__connectFailCount = 0;
    this.__isOnLine = window.navigator.onLine;
    window.addEventListener('offline', this.__onOffline.bind(this))
    window.addEventListener('online', this.__onOnline.bind(this))
    this.__init()
  }

  __init() {
    const SOCKET_URL =
      (window.location.hostname === 'wordwolf.net') ? SOCKET_URL_PROD : SOCKET_URL_DEV
    this.socketCreationDate = Date.now()
    this.socket = new WebSocket(SOCKET_URL)
    this.socket.onopen = this.__onOpen.bind(this)
    this.socket.onclose = this.__onClose.bind(this)
    this.socket.onerror = this.__onError.bind(this)
    this.socket.onmessage = this.__onMessage.bind(this)
  }

  async __onOpen(e) {
    console.log('WebSocket creation took ' + (Date.now() - this.socketCreationDate) + "ms")
    this.__connectFailCount = 0;
    await this.__onConnectHandler()
    const copiedQueue = this.__queue
    this.__queue = []

    copiedQueue.map((x) => {
      return this.__sendInternal(x)
    });
  }

  __onOnline(e) {
    this.__isOnLine = true;
    this.__reconnect('Network become online', true)
  }

  __onOffline(e) {
    this.__isOnLine = false;
    if (this.socket) {
      this.socket.close()
    }
  }

  __onClose(e) {
    console.log('Connection Closed Reason: ',e)
    if (e.code === 1000) {
      // Normal connection close. Retrying
      this.__reconnect('Connection Closed')
    }
  }

  __onError(e) {
    console.log('Connection Error Reason: ',e)
    this.__connectFailCount++;
    this.__reconnect('onError called');
  }

  __reconnect(reason, force=false) {
    if (!this.__isOnLine) {
      // Do nothing on offline
      return;
    }
    if (this.socket && this.socket.readyState === 0) {
      // New connection is in progress. do nothing.
      return;
    }
    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
    console.log('Reconnection requested:', reason)

    if (this.__connectFailCount === 0 || force) {
      // Force reconnect try init immediately
      this.__init()
      return;
    }

    if (this.__connectFailCount > 10) {
      // GiveUp auto reconnection
      return
    }

    let backoffTimeout = Math.pow(2, this.__connectFailCount) * 1000
    console.log('Waiting ' + (backoffTimeout / 1000) + ' sec for next reconnect')
    setTimeout(this.__init.bind(this), backoffTimeout)
  }

  __sendInternal(message) {
    console.log('Send:', message)
    if (!this.socket) { console.error('Error: Forgot to call __init.') }
    try {
      this.socket.send(JSON.stringify(message))
    } catch (e) {
      console.error(e)
      this.__queue.push(message)
      // If some error happens, reconnect and send it.
      this.__reconnect('send fail')
    }
  }

  __onMessage(json) {
    const message = JSON.parse(json.data)
    console.log('Recv:', message)
    this.__messageHandler(message)
  }

  send(message, forceReconnect=false) {
    if (this.socket === null) {
      // Unable to send the message. Keep saving in queue and send again when
      // the connection has re-established.
      this.socketCreationDatethis.__queue.push(message)
      this.__reconnect('Connection Close')
      return
    }

    switch (this.socket.readyState) {
      case 0:  // CONNECTING
        // Unable to send the message. Queue and do it when the connection
        // is established.
        this.__queue.push(message)
        break;

      case 1:  // OPEN
        this.__sendInternal(message);
        break;

      case 2:  // CLOSING
      case 3:  // CLOSED
        // Unable to send the message. Keep saving in queue and send again when
        // the connection has re-established.
        this.__queue.push(message)
        this.__reconnect('Connection Close', forceReconnect)
        break;
      default:
        console.error('Uknown socket state: ' + this.socket.readyState)
        break;
    }
  }
}

export default class RPCChannel {
  constructor(onConnectHandler) {
    this.channel = new JSONChannel(
      this.__onMessage.bind(this), this.__onConnect.bind(this))
    this.__onConnectHandler = onConnectHandler
    this.promises = {}
    this.__messageHandlers = []
    this.seqNo = 1
  }

  async __onConnect() {
    await this.__onConnectHandler()
  }

  __onMessage(msg) {
    const seq = msg.seqNo

    if (!seq) {
      // If there is no sequence number, this is not a reply call. call receiever
      this.__messageHandlers.forEach((handler) => { handler(msg) })
      return
    }

    var promise = this.promises[seq]
    if (!promise) { console.error('Unknown callback for message: ', msg) }

    const endDate = Date.now() - promise.beginDate
    console.log('API ' + msg.action + ":" + msg.method + " took " + endDate + "ms")

    // resolve the promise
    if (msg.result) {
      promise.resolve(msg.data) // resolve
    } else {
      if (msg.debugInfo) {
        console.log(msg.debugInfo)
      }
      promise.reject([msg.message, msg.errorId]) // reject
    }
  }

  addMessageHandler(callback) {
    this.__messageHandlers.push(callback)
  }

  send(message) {
    this.channel.send(message)
  }

  async init(playerId, roomId, gameId) {
    this.playerId = playerId
    const befClock = Date.now()
    const r = await this.call('init', '', { roomId: roomId, gameId: gameId})
    const aftClock = Date.now()
    const clientClock = (aftClock - befClock) / 2 + befClock
    const clockDiff = r.metadata.serverClock - clientClock
    Storage.serverProperty.set('clockDiff', clockDiff)

    if (r.player.id !== playerId) {
      console.log('The previous session has discarded')
    }
    this.playerId = r.player.id
    return {
      player: r.player,
      room: r.room,
      game: r.game,
      metadata: r.metadata
    }
  }

  async call(action, method, args, forceReconnect=false) {
    const seq = this.seqNo++
    const message = {
      namespace: 'wordwolf',
      action: action,
      method: method,
      seqNo: seq,
      playerId: this.playerId,
      data: args
    }

    const promise = new Promise((resolve, reject) => {
      this.promises[seq] = {
        resolve: resolve,
        reject: reject,
        beginDate: Date.now()
      }
    });

    this.send(message, forceReconnect)

    return promise
  }
}
