import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

class TextfieldDialog extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.initName,
    }
  }

  render() {
    const { onClose, title, message } = this.props
    return (
      <Dialog open={true} onClose={(x) => {onClose(null)}}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={this.state.value}
            onChange={(e) => {this.setState({value: e.target.value})}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(x) => {onClose(this.state.value)}} color="primary">
            OK
          </Button>
          <Button onClick={(x) => {onClose(null)}} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default TextfieldDialog
