import React from 'react';
import Sound from 'react-sound'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Storage from '../../storage/storage.js';
import soundFile from '../../sound/meka_ge_tokei_den_aramu01.mp3'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class AlarmController extends React.PureComponent {
  constructor(props) {
    super(props);

    const { game, player } = props

    const isHost = game.host === player.id

    this.state = {
      now: Date.now(),

      oneMinAlarm: isHost,
      oneMinAlarmPlayStatus: Sound.status.PLAYING,

      finishAlarm: isHost,
      finishAlarmPlayStatus: Sound.status.PLAYING,

      finishAlarmPlaying: false,
    }
  }

  componentDidMount() {
    this.timerHandle = setInterval(function() {
      this.setState({now: Date.now()})
    }.bind(this), 300)
  }

  componentWillUnmount() {
    clearInterval(this.timerHandle)
  }

  render() {
    const { classes, game } = this.props
    const { startClock, duration } = game
    const { now } = this.state

    const endDate =
      startClock + (duration * 1000) - Storage.serverProperty.get('clockDiff')

    const remaining = endDate < now ? 0 : (endDate - now) / 1000
    const oneMinAlarmActivate = this.state.oneMinAlarm && remaining < 60
    const finishAlarmActivate = remaining === 0 && this.state.finishAlarm
    return (
      <Paper className={classes.paper}>
        { oneMinAlarmActivate &&
          <Sound
            url={soundFile}
            playStatus={this.state.oneMinAlarmPlayStatus}
            onFinishedPlaying={ (x) => {
              this.setState({oneMinAlarmPlayStatus: Sound.status.STOPPED})
            }} />
        }
        { finishAlarmActivate &&
          <Sound
            url={soundFile}
            playStatus={this.state.finishAlarmPlayStatus}
            onLoad={(x) => {
              this.setState({finishAlarmPlaying: true})
            }}
          />
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.oneMinAlarm}
              onChange={(x) => {this.setState({oneMinAlarm: x.target.checked})}}
            />
          }
          label="一分前にアラームを鳴らす" />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.finishAlarm}
              onChange={(x) => {this.setState({finishAlarm: x.target.checked})}}
            />
          }
          label="終了時にアラームを鳴らす" />
        { this.state.finishAlarmPlaying &&
          <Button size="small" color="primary"
            onClick={(x) => {
              this.setState({
                finishAlarmPlaying: false,
                finishAlarmPlayStatus: Sound.status.STOPPED
              })
            }} >
            アラームを止める
          </Button>
        }
      </Paper>
    )
  }
}

export default withStyles(styles)(AlarmController)
