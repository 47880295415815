export default class BaseStorage {
  constructor(backend, key) {
    this.__storage_key = key
    this.__backend = backend
    try {
      this.__obj = JSON.parse(window.localStorage[this.__storage_key])
    } catch (e) {
      this.__obj = {}
      this.__backend[this.storage_key] = JSON.stringify(this.__obj)
    }
  }

  get(key) {
    return this.__obj[key]
  }

  set(key, value) {
    this.__obj[key] = value
    this.__backend[this.__storage_key] = JSON.stringify(this.__obj)
  }

  unset(key) {
    this.__obj.delete(key)
    this.__backend[this.__storage_key] = JSON.stringify(this.__obj)
  }

  reset() {
    this.__backend[this.__storage_key] = {}
  }
}
