import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import Client from './clients/client.js'
import Main from './ui/main.js';
import Preset from './util/preset.js'
import RPCChannel from './clients/channel.js'
import Storage from './storage/storage.js'
import WakeUpDetector from './util/wakeup_detector.js'

const gWakeupDetector = new WakeUpDetector()

class WordWolf extends React.PureComponent {
  constructor(props) {
    super(props)
    this.channel = new RPCChannel(this.__onConnect.bind(this))
    this.channel.addMessageHandler(this.__onMessage.bind(this));

    this.client = new Client(this.channel)

    gWakeupDetector.addCallback(this.__onWakeUp.bind(this));

    this.state = {
      player: null,
      room: null,
      game: null,
    }
  }

  async __onConnect() {
    const pId = Storage.global.get('player-id', null)
    const rId = Storage.global.get('room-id', null)
    const gId = Storage.global.get('game-id', null)
    const r = await this.channel.init(pId, rId, gId)
    Storage.global.set('player-id', r.player.id);
    Storage.global.set('room-id', r.room ? r.room.id : null);
    Storage.global.set('game-id', r.game ? r.game.id : null);

    await Preset.maybeInit(r.metadata.db_version, this.client)

    this.setState(r)
  }

  __onMessage(msg) {
    if (msg.action === 'room') {
      this.onRoomChange(msg.data);
    } else if (msg.action === 'game') {
      this.onGameChange(msg.data);
    }
  }

  __onWakeUp(elapsed) {
    console.log('wakeup event detected.')
    this.channel.call('ping', '', {}).then(() => {})
  }

  onNameChange(newName) {
    let p = {...this.state.player}
    p.name = newName
    this.setState({player: p})
  }

  onRoomChange(newRoom) {
    Storage.global.set('room-id', newRoom ? newRoom.id : null);
    // sanitize
    newRoom = newRoom ? (newRoom.id ? newRoom : null) : null
    this.setState({room: newRoom})
  }

  onGameChange(newGame) {
    Storage.global.set('game-id', newGame ? newGame.id : null);
    newGame = newGame ? (newGame.id ? newGame : null) : null
    this.setState({game: newGame})
  }

  render() {
    if (this.state.player == null) {
      return <div>初期化中</div>
    } else {
      return (
      <Main
        client={this.client}
        player={this.state.player}
        room={this.state.room}
        game={this.state.game}
        onNameChange={ this.onNameChange.bind(this) }
        onRoomChange={ this.onRoomChange.bind(this) }
        onGameChange={ this.onGameChange.bind(this) }
      />
      )
    }
  }
}


ReactDOM.render(
  <WordWolf />,
  document.getElementById('root')
);


