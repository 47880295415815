import Storage from '../storage/storage.js'
let gPreset = null

export default class Preset {
  static async maybeInit(dbVersion, client) {
    if (gPreset) { return; }

    const json = Storage.preset.get(dbVersion)
    if (json) { gPreset = JSON.parse(json); }
    if (gPreset) { return; }

    const r = await client.preset.get()
    gPreset = r.presets
    Storage.preset.set(dbVersion, JSON.stringify(gPreset))
  }

  static get(client) {
    return gPreset;
  }
};
