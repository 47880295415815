import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class OKDialog extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={(x) => {this.setState({open: false})}}>
        <DialogContent>
          <DialogContentText>{this.props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(x) => {this.setState({open: false})}} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default OKDialog;
