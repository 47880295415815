export default class PlayerClient {
  constructor(channel) {
    this.channel = channel
  }

  async set(name) {
    var r = await this.channel.call('player', 'set', { name: name })
    return r.player.name
  }

}
