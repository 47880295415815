import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

class RadioGroupDialog extends React.PureComponent {
  state = {
    selected: null
  }

  handleChange(x) {
    this.setState({selected: x.target.value})
  }

  render() {
    const onClose = this.props.onClose
    return (
      <Dialog
        open={true}
        onClose={(x) => {onClose(null)}}>
        <DialogTitle>ホストを渡す相手を選んでください。</DialogTitle>
        <DialogContent>
          <RadioGroup
            name="ringtone"
            value={this.state.selected}
            onChange={this.handleChange.bind(this)} >
            {this.props.players
              .filter(x => x.id !== this.props.player.id)
              .map(player => (
                <FormControlLabel
                  value={player.id}
                  key={player.id}
                  control={<Radio />}
                  label={player.name} />
              ))
            }
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={(x) => {onClose(this.state.selected)}} color="primary">
            OK
          </Button>
          <Button onClick={(x) => {onClose(null)}} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RadioGroupDialog
