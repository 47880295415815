import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PresetGameCreationCard from './preset_game_creation_card.js'
import CustomGameCreationCard from './custom_game_creation_card.js'

export default class GameCreationCard extends React.PureComponent {
  state = {
    tabValue: 0,
  };

  static getDerivedStateFromProps(props, state) {
    return (props.room.players.length < 4) ? { tabValue: 0 } : null
  }

  render() {
    const { room, player, client, onChange } = this.props;
    const { tabValue } = this.state;

    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs value={tabValue} onChange={(e,v) => this.setState({tabValue: v})}>
            <Tab label="プリセット" />
            { room.players.length >= 4 && <Tab label="カスタム" /> }
          </Tabs>
        </AppBar>
        {tabValue === 0 && <PresetGameCreationCard
          room={room} player={player} client={client} onChange={onChange}/>}
        {tabValue === 1 && <CustomGameCreationCard
          room={room} player={player} client={client} onChange={onChange}/>}
      </div>
    );
  }
}
