import React from 'react'

import Grid from '@material-ui/core/Grid';

import GameCreationCard from './game_creation_card.js'
import RoomCard from './room_card.js'

function Room(props) {
  const { client, player, room, game,
    onRoomChange, onGameChange, onNameChange } = props

    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <RoomCard client={client} player={player } room={room}
            onRoomChange={onRoomChange}
            onNameChange={onNameChange} />
        </Grid>
        { room && room.host === player.id &&
        <Grid item xs={12}>
          <GameCreationCard client={client}
            player= {player} room={room}
            game={game} onChange={onGameChange} />
        </Grid>
        }

      </Grid>
    )
}

export default Room;
