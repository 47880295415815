import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import VotingView from './game_voting_view.js'
import ObserverController from './game_observer_controller.js'
import CountdownTimer from './countdown_timer.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

const stateString = (state) => {
  switch (state) {
    case 'CREATED': return '準備中';
    case 'PLAYING': return 'ゲーム中';
    case 'VOTING': return '投票中';
    case 'WORD_GUESS': return 'キーワード予想中';
    case 'MINORITY_WIN': return '少数派の勝利';
    case 'MAJORITY_WIN': return '多数派の勝利';
    case 'TERMINATED': return '中断しました';
    default:
      return state;
  }
}

function playerChips(players) {
  return players.map(player => <Chip key={player.id} label={player.name} />)
}

function GameDetail(props) {
  const { classes, game } = props
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Typography color="textSecondary">テーマ</Typography>
          <Typography variant="h5" component="h2">{game.theme}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color="textSecondary">ゲーム時間</Typography>
          <Typography variant="h5" component="h2">{game.duration / 60}分</Typography>
        </Grid>
        { game.state === 'PLAYING' &&
        <Grid item xs={6}>
          <Typography color="textSecondary">残り時間</Typography>
          <Typography variant="h5" component="h2"><CountdownTimer game={game} /></Typography>
        </Grid>
        }
        <Grid item xs={12}>
          <Typography color="textSecondary">ゲームの進行度</Typography>
          <Typography variant="h5" component="h2">
           {stateString(game.state)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

function GameObserverView(props) {
  const { classes, game, player, onChange, client, title } = props;
  return (
    <div className={classes.root}>
     <Grid container spacing={24}>
       <Grid item xs={12}>
          <Typography variant="h5" component="h2" align="center">{title}</Typography>
       </Grid>
       <Grid item xs={12}>
          <GameDetail classes={classes} game={props.game} />
       </Grid>
       <Grid item xs={12}>
         <ObserverController classes={classes} game={game} player={player}
            onChange={onChange} client={client}/>
       </Grid>
       <Grid item xs={12}>
         <Paper className={classes.paper}>
           <Typography variant="h6" align="center">多数派</Typography>
           <Typography color="textSecondary">キーワード</Typography>
           <Typography variant="h5" component="h2">
             {props.game.majWord}
           </Typography>
           <Typography color="textSecondary">メンバー</Typography>
           { playerChips(props.game.majPlayers) }
         </Paper>
       </Grid>
       <Grid item xs={12}>
         <Paper className={classes.paper}>
           <Typography variant="h6" align="center">少数派</Typography>
           <Typography color="textSecondary">キーワード</Typography>
           <Typography variant="h5" component="h2">
             {props.game.minWord}
           </Typography>
           <Typography color="textSecondary">メンバー</Typography>
           { playerChips(props.game.minPlayers) }
         </Paper>
       </Grid>
       <Grid item xs={12}>
         <VotingView classes={classes} game={props.game} />
       </Grid>
     </Grid>
    </div>
  )
}

GameObserverView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameObserverView);
