import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  }
});

class GameCreated extends React.PureComponent {
  state = {
    wordVisible: false
  }

  keyword() {
    const { game } = this.props

    if (this.state.wordVisible) {
      return (
        <>
          <Typography variant="h5" >あなたのキーワードは</Typography>
          <Typography variant="h3"
            onClick={(x) => {this.setState({wordVisible: false})}} >
            「{game.word}」
          <Typography variant="h5" >です。</Typography>
        </Typography>
       </>
      )
    } else {
      return (
        <>
          <Typography variant="h5"
            onClick={(x) => {this.setState({wordVisible: true})}} >
            (ここをタップしてキーワードを確認)
          </Typography>
       </>
      )
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.paper}>
        { this.keyword() }
          <Typography variant="h5" >ゲームが始まるのを待っています。</Typography>
      </Paper>
    )
  }
}

GameCreated.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameCreated);
