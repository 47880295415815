export default class PresetClient {
  constructor(channel) {
    this.channel = channel
  }

  async get() {
    return await this.channel.call('preset', 'list', {})
  }

}
