import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class OKCancelDialog extends React.PureComponent {
  render() {
    const onClose = this.props.onClose
    return (
      <Dialog
        open={true}
        onClose={(x) => {onClose(false)}}>
        <DialogContent>
          <DialogContentText>{this.props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(x) => {onClose(true)}} color="primary">
            OK
          </Button>
          <Button onClick={(x) => {onClose(false)}} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default OKCancelDialog;
