import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Storage from '../../storage/storage.js';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class CountdownTimer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      now: Date.now(),
    }
  }

  componentDidMount() {
    this.timerHandle = setInterval(function() {
      this.setState({now: Date.now()})
    }.bind(this), 300)
  }

  componentWillUnmount() {
    clearInterval(this.timerHandle)
  }

  render() {
    const { startClock, duration } = this.props.game
    const { now } = this.state

    const endDate =
      startClock + (duration * 1000) - Storage.serverProperty.get('clockDiff')

    const remaining = endDate < now ? 0 : endDate - now
    const min = Math.floor(remaining / (60 * 1000))
    const sec = Math.floor((remaining - (min * 60 * 1000)) / 1000)

    return (<>{min}分{sec}秒</>)
  }
}

export default withStyles(styles)(CountdownTimer)
