import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Preset from '../../util/preset.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
});

class CustomGameCreationCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.presets = Preset.get(props.client)
    this.state = {
      themeValue: "",
      themeError: "",
      majValue: "",
      majError: "",
      minValue: "",
      minError: "",
      minorityCount: 1,
      talkTime: 300,
    }
  }

  validateTheme() {
    const { themeValue } = this.state
    if (themeValue.length === 0) {
      return [ false, 'テーマを指定してください' ]
    }
    if (themeValue.length > 64) {
      return [ false, 'テーマは64文字以内でお願いします' ]
    }
    return [true, '']
  }

  validateMajority() {
    const { majValue } = this.state
    if (majValue.length === 0) {
      return [ false, '多数派キーワードを指定してください' ]
    }
    if (majValue.length > 16) {
      return [ false, '多数派キーワードは16文字以内でお願いします' ]
    }
    return [true, '']
  }

  validateMinority() {
    const { minValue } = this.state
    if (minValue.length === 0) {
      return [ false, '少数派キーワードを指定してください' ]
    }
    if (minValue.length > 16) {
      return [ false, '少数派キーワードは16文字以内でお願いします' ]
    }
    return [true, '']
  }

  handleCreate() {
    const [ themeValid, themeErr ] = this.validateTheme()
    const [ majValid, majWordErr] = this.validateMajority()
    const [ minValid, minWordErr] = this.validateMinority()

    if (!themeValid || !majValid || !minValid) {
      this.setState({
        themeError: themeErr,
        majError: majWordErr,
        minError: minWordErr
      })
      return
    }

    this.props.client.game.create_custom(
      this.props.room.id,
      this.state.minorityCount,
      this.state.themeValue,
      this.state.majValue,
      this.state.minValue,
      this.state.talkTime
    ).then((newGame) => { this.props.onChange(newGame) })
  }

  expandItems(items) {
      return items.map((x) => {
        return <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
      })
  }

  render() {
    const { room, classes } = this.props

    const minorities = []
    const maxMinorityCount = Math.floor((room.players.length - 1) / 2)
    for (let i = 1; i <= Math.max(1, maxMinorityCount); ++i) {
      minorities.push({ value: i, label: i + "人"})
    }

    const talkTimes = []
    for (let i = 1; i < 30; i++) {
      talkTimes.push({ value: i * 60, label: i + "分"})
    }

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                error={this.state.themeError.length !== 0}
                required
                label="テーマ"
                value={this.state.themeValue}
                onChange={(x) => { this.setState({ themeValue: x.target.value }) }}/>
              { this.state.themeError.length !== 0 &&
                <FormHelperText error>{this.state.themeError}</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField fullWidth required
                label="多数派キーワード"
                error={this.state.majError.length !== 0}
                value={this.state.majValue}
                onChange={(x) => { this.setState({ majValue: x.target.value }) }}/>
              { this.state.majError.length !== 0 &&
                <FormHelperText error>{this.state.majError}</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField fullWidth required
                label="少数派キーワード"
                error={this.state.minError.length !== 0}
                value={this.state.minValue}
                onChange={(x) => { this.setState({ minValue: x.target.value }) }}/>
              { this.state.minError.length !== 0 &&
                <FormHelperText error>{this.state.minError}</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">少数派の人数</Typography>
            <Select value={this.state.minorityCount} fullWidth
              onChange={(x) => { this.setState({minorityCount: x.target.value}) }} >
              { this.expandItems(minorities) }
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">トーク時間</Typography>
            <Select value={this.state.talkTime} fullWidth
              onChange={(x) => { this.setState({talkTime: x.target.value}) }} >
              { this.expandItems(talkTimes) }
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button size="small" color="primary"
              onClick={it => this.handleCreate() }>ゲームを開始する</Button>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              ※カスタムのお題でゲームを始めると、ホストは観戦モードになります。
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(CustomGameCreationCard)
