import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Preset from '../../util/preset.js'
import Random from '../../util/random.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class PresetGameCreationCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.presets = Preset.get(props.client)
    this.state = {
      selectedCategory: 0,
      selectedTheme: -1,
      minorityCount: 1,
      talkTime: 300,
    }
  }

  handleCreate() {
    let theme
    if (this.state.selectedTheme === -1) {
      const themes = this.presets[this.state.selectedCategory].themes
      theme = themes[Random.rand(0, themes.length)].id
    } else {
      theme = this.state.selectedTheme
    }
    this.props.client.game.create(
      this.props.room.id,
      this.state.minorityCount,
      theme,
      this.state.talkTime
    ).then((newGame) => { this.props.onChange(newGame) })
  }

  expandItems(items) {
      return items.map((x) => {
        return <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
      })
  }

  render() {
    const { room, classes } = this.props

    const categories = this.presets.map((x, index) => {
      return { value: index, label: x.title }
    })

    let themes;
    if (this.presets.length === 0) {
      themes = []
    } else {
      const themesValues = this.presets[this.state.selectedCategory]
      themes = themesValues.themes.map((x) => {
        return { value: x.id, label: x.title }
      })
    }

    const minorities = []
    const maxMinorityCount = Math.floor(room.players.length / 2)
    for (let i = 1; i <= Math.max(1, maxMinorityCount); ++i) {
      minorities.push({ value: i, label: i + "人"})
    }

    const talkTimes = []
    for (let i = 1; i < 30; i++) {
      talkTimes.push({ value: i * 60, label: i + "分"})
    }

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <Typography color="textSecondary">カテゴリ</Typography>
            <Select value={this.state.selectedCategory} fullWidth
              onChange={(x) => {
                this.setState({
                  selectedCategory: x.target.value,
                  selectedTheme: -1
                })
              }}>
              { this.expandItems(categories) }
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">テーマ</Typography>
            <Select value={this.state.selectedTheme} fullWidth
              onChange={(x) => { this.setState({selectedTheme: x.target.value}) }} >
              <MenuItem value={-1}>(ランダム)</MenuItem>
              { this.expandItems(themes) }
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">少数派の人数</Typography>
            <Select value={this.state.minorityCount} fullWidth
              onChange={(x) => { this.setState({minorityCount: x.target.value}) }} >
              { this.expandItems(minorities) }
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Typography color="textSecondary">トーク時間</Typography>
            <Select value={this.state.talkTime} fullWidth
              onChange={(x) => { this.setState({talkTime: x.target.value}) }} >
              { this.expandItems(talkTimes) }
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Button size="small" color="primary"
              onClick={it => this.handleCreate() }>
              ゲームを開始する
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(PresetGameCreationCard)
