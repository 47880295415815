import React from 'react'
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class Welcome extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: ""
    }
  }

  render() {
    const { client, onNameChange, classes } = this.props

    return(
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              ワード人狼へようこそ。
            </Typography>
            <Typography variant="h6" component="h2">
              あなたの名前を入力してください。
            </Typography>
            <Typography variant="h6" component="h2">
              注)入力した内容は他のプレイヤーに通知されます。
            </Typography>
            <TextField
              value={this.state.name}
              onChange={(e) => {this.setState({name: e.target.value})}} />
            <Button onClick={(x) => {
              if (this.state.name.length !== 0) {
                client.player.set(this.state.name).then(onNameChange)
              } 
            }} color="primary">
              送信
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Welcome);
