
export default class RoomClient {
  constructor(channel) {
    this.channel = channel
  }

  async create(title, secret) {
    return await this.channel.call('room', 'create', {
      name: title,
      secret: secret
    });
  }

  async join(secret, isPlayer) {
    return await this.channel.call('room', 'join', {
      secret: secret, isPlayer: isPlayer });
  }

  async leave(roomId) {
    return await this.channel.call('room', 'leave', { roomId: roomId } )
  }

  async kick(roomId, target) {
    return await this.channel.call('room', 'kick', {
      roomId: roomId, target: target
    } )
  }

  async transferHost(roomId, target) {
    return await this.channel.call('room', 'transfer_host', {
      roomId: roomId, target: target
    } )
  }

  async toPlayer(roomId) {
    return await this.channel.call('room', 'to_player', { roomId: roomId } )
  }

  async toObserver(roomId) {
    return await this.channel.call('room', 'to_observer', { roomId: roomId } )
  }

}
