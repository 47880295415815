import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

function TerminateButton(props) {
  const { game, onChange, client } = props;
  return (<Button size="small" color="primary" onClick={(x) => {
            client.game.terminate(game.id).then(onChange);
          }}>
           ゲームを終了する 
          </Button>)
}

function ObserverController(props) {
  const { classes, game, onChange, client, player } = props;

  const showControl =
    (game.state === 'MINORITY_WIN' || game.state === 'MAJORITY_WIN'
      || game.state === 'TERMINATED') || (game.host === player.id)

  if (!showControl) {
    return <></>
  }

  switch (game.state) {
    case 'CREATED':
      return (
        <Paper className={classes.paper}>
          <Button size="small" color="primary" onClick={(x) => {
            client.game.start(game.id).then(onChange);
          }}>
            ゲームを開始する
          </Button>
          <TerminateButton game={game} onChange={onChange} client={client} />
        </Paper>
      )
    case 'PLAYING':
      return (
        <Paper className={classes.paper}>
          <Button size="small" color="primary" onClick={(x) => {
            client.game.quit_talk(game.id).then(onChange)
          }}>
            投票を開始する
          </Button>
        </Paper>
      )
    case 'VOTING':
      return (
        <Paper className={classes.paper}>
          <Button size="small" color="primary" onClick={(x) => {
            let atLeastOneSubmitted = false
            if (game.vote) {
              atLeastOneSubmitted = Object.keys(game.vote).length !== 0
            } else {
              atLeastOneSubmitted = game.votedPlayers.length !== 0
            }
            if (atLeastOneSubmitted) {
              client.game.close_vote(game.id).then(onChange)
            }
          }}>
            投票を締め切る
          </Button>
        </Paper>
      )
    case 'WORD_GUESS':
      return (
        <Paper className={classes.paper}>
          <Typography variant="h5">
            お題を当てることはできましたか？
          </Typography>
          <Button size="small" color="primary" onClick={(x) => {
            client.game.word_guess(game.id, true).then((g) => onChange(g))
          }}>
            正解した
          </Button>
          <Button size="small" color="primary" onClick={(x) => {
            client.game.word_guess(game.id, false).then ((g) => onChange(g))
          }} >
            間違えた
          </Button>
        </Paper>
      )
    case 'MINORITY_WIN':
    case 'MAJORITY_WIN':
    case 'TERMINATED':
      return (
        <Paper className={classes.paper}>
          <Button size="small" color="primary" onClick={(x) => {onChange(null)}}>
            ゲーム画面を閉じる
          </Button>
        </Paper>
      )
    default:
      return (<div>Unknown State: {game.state}</div>)
  }
}

ObserverController.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ObserverController);
