import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Flag from '@material-ui/icons/Flag';

import OKCancelDialog from '../common/ok_cancel_dialog.js'
import RadioGroupDialog from '../common/radiogroup_dialog.js'
import TextfieldDialog from '../common/textfield_dialog.js'
import RoomCreateForm from './create_form.js'
import RoomJoinForm from './join_form.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  },
});

class NoRoomCard extends React.PureComponent {
  state = {
    page: 'landing'
  }

  render() {
    if (this.state.page === 'landing') {
      return (
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              現在部屋に入っていません。
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary"
              onClick={it => this.setState({page:'join_room'})}>
              合言葉で入室する
            </Button>
            <Button size="small" color="primary"
              onClick={it => this.setState({page:'new_room'})}>
              新しく部屋を作る
            </Button>
          </CardActions>
        </Card>
      )
    } else if (this.state.page === 'join_room') {
      return (<RoomJoinForm
        client={this.props.client}
        onRoomJoin={this.props.onRoomJoin}
        onCancel={it => this.setState({page:'landing'})}
      />)
    } else if (this.state.page === 'new_room') {
      return (<RoomCreateForm
        client={this.props.client}
        onRoomJoin={this.props.onRoomJoin}
        onCancel={it => this.setState({page:'landing'})}
      />)
    }
  }
}

class CurrentRoomCard extends React.PureComponent {
  state = {
    kickTarget: null,
    showHostTransferDialog: false,
    confirmStatusUpdate: false,
    showNameChangeDialog: false,
  }

  handleLeave() {
    this.props.client.room.leave(this.props.room.id).then(() => {
      this.props.onRoomChange(null)
    })
  }

  handleDelete(player) {
    this.setState({kickTarget: player})
    return false
  }

  handleKick(yesNo) {
    const player = this.state.kickTarget
    this.setState({kickTarget: null})

    if (yesNo) {
      this.props.client.room.kick(this.props.room.id, player.id).then((x) => {
        this.props.onRoomChange(x);
      });
    }
  }

  chip(room, player, isHost) {
    const isPlayerHost = room.host === player.id
    const onDelete = (isHost && !isPlayerHost)
      ? (x) => this.handleDelete(player) : null
    return <Chip
      avatar={isPlayerHost ? <Avatar><Flag /></Avatar> : <></>}
      color={player.status === 'CONNECTED' ? "primary" : "secondary"}
      key={player.id}
      onDelete={onDelete}
      label={player.name} />
  }

  user_cards() {
    const isHost = this.props.room.host === this.props.player.id
    const sorted = [...this.props.room.players]
    sorted.sort((x, y) => {
      if (x.id < y.id) { return -1 }
      if (x.id > y.id) { return 1 }
      else { return 0}
    })

    return sorted.map(player => this.chip(this.props.room, player, isHost))
  }

  kickConfirmDialog() {
    if (this.state.kickTarget != null) {
      return (<OKCancelDialog
          message={this.state.kickTarget.name + "を追い出しますか？"}
          onClose={this.handleKick.bind(this)}
        />)
    } else {
      return (<></>)
    }
  }

  handleTransferHost(playerId) {
    this.setState({showHostTransferDialog:false})
    if (playerId != null) {
      this.props.client.room.transferHost(this.props.room.id, playerId).then((x) => {
        this.props.onRoomChange(x)
      })
    }
  }

  hostTransferDialog() {
    if (this.state.showHostTransferDialog) {
      return (<RadioGroupDialog
        player={this.props.player}
        players={this.props.room.players}
        onClose={this.handleTransferHost.bind(this)}
        />)
    } else {
      return (<></>)
    }
  }

  updateStateDialog(isObserver) {
    if (this.state.confirmStatusUpdate) {
      return (<OKCancelDialog
          message={(isObserver ? 'プレイヤー' : '観戦者') + "になりますか？"}
          onClose={this.handleUpdateState.bind(this, isObserver)}
        />)
    } else {
      return (<div></div>)
    }
  }

  nameChangeDialog() {
    if (this.state.showNameChangeDialog) {
      return <TextfieldDialog
        onClose={(x) => {
          this.setState({showNameChangeDialog: false})
          if (x !== null) {
            this.props.client.player.set(x).then(this.props.onNameChange)
          }
        }}
        initName={this.props.player.name}
        title='プレイヤー名を入力してください。'
        message='全プレイヤーに公開されます。'
        />
    } else {
      return (<div></div>)
    }
  }

  handleUpdateState(isObserver, yesNo) {
    this.setState({confirmStatusUpdate: false})
    if (yesNo) {
      if (isObserver) {
        this.props.client.room.toPlayer(this.props.room.id).then((x) => {
          this.props.onRoomChange(x);
        })
      } else {
        this.props.client.room.toObserver(this.props.room.id).then((x) => {
          this.props.onRoomChange(x);
        })
      }
    }
  }

  render() {
    const { player, room, classes } = this.props
    const isHost = this.props.room.host === this.props.player.id
    const observerIds = new Set(this.props.room.observers.map(x => x.id))
    const isObserver = observerIds.has(this.props.player.id)

    return (
      <div>
        {this.kickConfirmDialog()}
        {this.hostTransferDialog()}
        {this.updateStateDialog(isObserver)}
        {this.nameChangeDialog()}
        <Paper className={classes.paper}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography color="textSecondary">プレイヤー名(変更するには名前をタップ)</Typography>
              <Typography variant="h5" component="h2"
                onClick={(x) => { this.setState({showNameChangeDialog: true})}} >
                {player.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">部屋名</Typography>
              <Typography variant="h5" component="h2">{room.roomName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">合言葉</Typography>
              <Typography variant="h5" component="h2">{room.secret}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="textSecondary">参加者</Typography>
              <Typography variant="h6" component="h2">
                {room.players.length}人
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="textSecondary">観戦者</Typography>
              <Typography variant="h6" component="h2">
                {room.observers.length}人
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="textSecondary">状態</Typography>
              <Typography variant="h6" component="h2">
                {isObserver ? "観戦中" : "プレイ中"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              { this.user_cards() } 
            </Grid>
            <Grid item xs={4}>
              <Button size="small" color="primary" onClick={it => this.handleLeave()}>
                退室
              </Button>
            </Grid>
            <Grid item xs={4}>
              { isHost &&
              <Button size="small" color="primary"
                onClick={it => this.setState({showHostTransferDialog: true})}>
                ホスト変更
              </Button>
              }
            </Grid>
            <Grid item xs={4}>
              <Button size="small" color="primary"
                onClick={it => this.setState({confirmStatusUpdate: true})}>
                {isObserver ? '参加する' : '観戦する'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

function RoomCard(props) {
  const { room, player, client, onRoomChange, onNameChange, classes } = props
  if (props.room) {
    return <CurrentRoomCard classes={classes} player={player} room={room}
      client={client}
      onRoomChange={onRoomChange}
      onNameChange={onNameChange} />
  } else {
    return <NoRoomCard client={client} onRoomJoin={onRoomChange} />
  }
}

RoomCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoomCard)
