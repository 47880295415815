
const CHECK_INTERVAL = 2000;  // 2 sec

// Assume 60 sec non-responsisve for the setInterval as in the sleep mode.
const SLEEP_NONACTIVE_DURATION = 60000;  // 60 sec

export default class WakeUpDetector {
  constructor() {
    this.__wakeupHandlers = []
    this.__lastActiveDate = Date.now()
    this.__intervalHandler = setInterval(this.__watchdog.bind(this), CHECK_INTERVAL)
  }

  addCallback(onWakeUp) {
    this.__wakeupHandlers.push(onWakeUp)
  }

  __watchdog() {
    const now = Date.now()
    const elapsed = (now - this.__lastActiveDate)
    if (elapsed >= SLEEP_NONACTIVE_DURATION) {
      this.__wakeupHandlers.forEach((func) => { return func(elapsed); })
    }
    this.__lastActiveDate = now
  }

  // TODO: Release timer?
};
