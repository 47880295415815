import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import OKCancelDialog from '../common/ok_cancel_dialog.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
  }
});

class GameVoting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmVote: false,
      vote: null
    }
  }

  handleClick(vote) {
    this.setState({vote: vote, confirmVote: true})
  }

  handleVote(yesNo) {
    const vote = this.state.vote
    const { client, game, onChange } = this.props
    this.setState({vote: null, confirmVote:false})
    if (yesNo) {
      client.game.vote(game.id, vote).then(onChange)
    }
  }

  confirmDialog(idName) {
    if (this.state.confirmVote) {
      return (<OKCancelDialog
        message={idName[this.state.vote] + "に投票します。"}
        onClose={this.handleVote.bind(this)} />)
    } else {
      return (<></>)
    }
  }

  render() {
    const { game, player, classes } = this.props
    const voted = game.votedPlayers.includes(player.id)

    if (voted) {
      if (game.votedPlayers.length === game.players.length) {
        return (
          <Paper className={classes.paper}>
            <Typography variant="h5" >ホストの入力を待っています。</Typography>
          </Paper>
        )
      } else {
        return (
          <Paper className={classes.paper}>
            <Typography variant="h5" >以下の人の選択を待っています</Typography>
              {game.players
                .filter(x => !game.votedPlayers.includes(x.id))
                .map(x => <Chip key={x.id} label={x.name} />)
              }
          </Paper>
        )
      }
    } else {
      const idName = game.players.reduce((map, obj) => {
        map[obj.id] = obj.name;
         return map;
      }, {});
      return (
        <Paper className={classes.paper}>
          {this.confirmDialog(idName)}
          <Typography variant="h5" >少数派だと思う人を選んでください</Typography>
            {game.minorityCandidates.map(x => 
              <Chip key={x} label={idName[x]} color="primary"
                onClick={ () => { this.handleClick(x) } }/>)
            }
        </Paper>
      )
    }
  }
}

GameVoting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameVoting)
