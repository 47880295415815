
export default class GameClient {
  constructor(channel) {
    this.channel = channel
  }

  async create(roomId, minCount, themeId, duration) {
    return await this.channel.call('game', 'create', {
      roomId: roomId,
      minority_count: minCount,
      theme_id: themeId,
      duration: duration,
    });
  }

  async create_custom(roomId, minCount, theme, majWord, minWord, duration) {
    return await this.channel.call('game', 'create_custom', {
      roomId: roomId,
      minority_count: minCount,
      theme: theme,
      minWord: minWord,
      majWord: majWord,
      duration: duration,
    });
  }

  async start(gameId) {
    return await this.channel.call('game', 'start', { gameId: gameId })
  }

  async quit_talk(gameId) {
    return await this.channel.call('game', 'quit_talk', { gameId: gameId })
  }

  async vote(gameId, vote) {
    return await this.channel.call('game', 'vote', {
      gameId: gameId,
      vote: vote
    })
  }

  async close_vote(gameId) {
    return await this.channel.call('game', 'close_vote', { gameId: gameId })
  }

  async word_guess(gameId, correct) {
    return await this.channel.call('game', 'word_guess', {
      gameId: gameId,
      wordCorrect: correct
    });
  }

  async terminate(gameId) {
    return await this.channel.call('game', 'terminate', { gameId: gameId })
  }
}
